<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Forwarder <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="forwarders"
                  item-text="name"
                  return-object
                  v-model="vendor"
                  @change="onChangeVendor"
                  :rules="[v => !!v || 'Forwarder is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.paymentDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.paymentDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuStart = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.voucherNumber"
                  :rules="[v => !!v || 'Voucher Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="12" md="8" lg="8">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-row>
                  <v-col>
                    <v-checkbox label="Giro" value="Giro" v-model="methods"></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox label="Cash" value="Cash" v-model="methods"></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox label="Transfer" value="Transfer" v-model="methods"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="accountName"
                  return-object
                  v-model="bank"
                  :items="listBanks"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on" @click.stop="addBankAccount(data.item)">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.accountNumber"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="form.accounts.length > 0">
          <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
            <account-list :items="form.accounts" @deleteItem="deleteItem"></account-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charge</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  prefix="Rp."
                  v-model.number="form.bankCharge"
                  :rules="[v => v >= 0 || 'Bank charge must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab>
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <v-autocomplete
                        flat
                        dense
                        outlined
                        background-color="white"
                        item-text="invoiceNumber"
                        append-icon="mdi-magnify"
                        return-object
                        :items="listInvoice"
                        v-model="invoice"
                      >
                        <template v-slot:item="data">
                          <v-list-item v-on="data.on" @click.stop="onChangeInvoice(data.item)">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.invoiceNumber"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Invoice <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.invoices"
                        @click:row="onClickRow"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.invoices.map(x => x.referenceId).indexOf(item.referenceId) + 1 }}
                        </template>
                        <template v-slot:item.totalAmountAfterTax="{ item }">
                          {{ formatPrice(item.totalAmountAfterTax) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalInvoiceIDR="{ item }">
                          {{ formatPrice(item.totalInvoiceIDR) }}
                        </template>
                        <template v-slot:item.invoicePaid="{ item }">
                          {{ formatPrice(item.invoicePaid) }}
                        </template>
                        <template v-slot:item.totalInvoicePaidIDR="{ item }">
                          {{ formatPrice(item.totalInvoicePaidIDR) }}
                        </template>
                        <template v-slot:item.fullPayment="{ item }">
                          {{ item.fullPayment ? "YES" : "NO" }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="checkJournal">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-invoice
      :dialog="dialogInvoice"
      :item="item"
      :index="index"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItemInvoice"
    ></dialog-invoice>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      currency="IDR"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import AccountList from "@/components/AccountList";
import DialogInvoice from "@/components/DialogInvoice";
import { mapState } from "vuex";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-purchase-other-payment",
  mixins: [fiscalMonthMixin],
  components: {
    "account-list": AccountList,
    "dialog-invoice": DialogInvoice,
    DialogReviewJournal,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      voucherNumber: "",
      paymentDate: moment().format("yyyy-MM-DD"),
      vendorName: "",
      vendorId: null,
      paymentMethod: "",
      bankCharge: 0,
      remark: "",
      accounts: [],
      invoices: [],
      status: "Outstanding",
    },
    menuStart: false,
    dateStart: new Date().toISOString().substr(0, 10),
    form: {},
    dialogInvoice: false,
    dialogReview: false,
    item: {},
    index: 0,
    bank: null,
    vendor: null,
    invoice: null,
    methods: [],
    listInvoice: [],
    listBanks: [],
    valid: true,
    journals: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice #",
        value: "invoiceNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Date",
        value: "invoiceDate",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "totalAmountAfterTax",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Invoice (RP)",
        value: "totalInvoiceIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Paid",
        value: "invoicePaid",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Paid (RP)",
        value: "totalInvoicePaidIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Full Payment",
        value: "fullPayment",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    ...mapState("purchaseOtherPayment", ["lastFormNumber"]),
    ...mapState("external", ["forwarders"]),
  },

  watch: {},

  methods: {
    checkJournal() {
      if (
        this.$refs.form.validate() &&
        this.methods.length > 0 &&
        this.form.accounts.length > 0 &&
        this.form.invoices.length > 0
      ) {
        this.journals = [];
        this.form.invoices.forEach(x => {
          this.journals.unshift({
            accountNumber: x.accountNumber,
            accountName: x.accountName,
            debit: x.invoicePaid,
            credit: 0,
          });
        });
        if (this.form.bankCharge > 0) {
          this.journals.unshift({
            accountNumber: "916.00001",
            accountName: `BIAYA ADMINISTRASI BANK`,
            debit: this.form.bankCharge,
            credit: 0,
          });
        }
        this.form.accounts.forEach(x => {
          this.journals.push({
            accountNumber: x.accountNumber,
            accountName: x.accountName,
            debit: 0,
            credit: x.amount,
          });
        });
        this.journals.forEach(x => {
          this.$store
            .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
            .then(response => (x.pointers = response.data));
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    submit() {
      if (
        this.$refs.form.validate() &&
        this.methods.length > 0 &&
        this.form.accounts.length > 0 &&
        this.form.invoices.length > 0
      ) {
        this.form.formNumber = this.lastFormNumber;
        this.form.paymentMethod = this.methods.toString();

        let totalDebit = this.form.bankCharge;
        let totalCredit = 0;

        this.form.invoices.forEach(x => {
          totalDebit += x.invoicePaid;
        });

        this.form.accounts.forEach(x => {
          totalCredit += x.amount;
        });

        if (totalDebit - totalCredit === 0) {
          this.$store.dispatch("purchaseOtherPayment/create", this.form);
          this.clear();
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Dredit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Methods , Invoices, and Accounts is required",
        });
      }
    },
    onChangeVendor(val) {
      this.form.vendorId = val.id;
      this.form.vendorName = val.name;
      this.getListInvoice(val.id);
    },
    getListInvoice(vendorId) {
      this.$store.dispatch("purchaseOtherPayment/getListInvoice", vendorId).then(response => {
        this.listInvoice = response.data;
      });
    },
    onChangeInvoice(val) {
      this.form.invoices.push(val);
      this.item = val;
      this.index = this.form.invoices.map(x => x).indexOf(val);
      this.dialogInvoice = true;
    },
    addBankAccount(item) {
      this.form.accounts.push(item);
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.form.invoices.map(x => x.id).indexOf(item.id);
      this.dialogInvoice = true;
    },
    deleteItem(index) {
      if (index > -1) {
        this.form.accounts.splice(index, 1);
      }
    },
    addItem(item) {
      this.form.invoices[this.index] = item;
    },
    deleteItemInvoice(index) {
      if (index > -1) {
        this.form.invoices.splice(index, 1);
        this.dialogInvoice = false;
      }
    },
    close() {
      this.dialogInvoice = this.dialogReview = false;
    },
    clear() {
      this.vendor = null;
      this.bank = null;
      this.invoice = null;
      this.methods = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getBanks() {
      this.$store.dispatch("chartOfAccount/getBanks").then(response => {
        this.listBanks = response.data;
      });
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },

  created() {
    this.$store.dispatch("purchaseOtherPayment/getLastFormNumber");
    this.$store.dispatch("external/getForwarders"), this.getBanks();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
